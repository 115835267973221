import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Container, Row, Col } from 'tunggal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`In the grid system, we define the frame outside the information area based on row and column, to ensure that every area can have stable arrangement.`}</p>
    </PageDescription>
    <p>{`Following is a brief look at how it works:`}</p>
    <ul>
      <li parentName="ul">{`Establish a set of column in the horizontal space defined by row (abbreviated col)`}</li>
      <li parentName="ul">{`Your content elements should be placed directly in the col, and only col should be placed directly in row`}</li>
      <li parentName="ul">{`The column grid system is a value of 1-12 to represent its range spans. For example, three columns of equal width can be created by `}<Col span={8} mdxType="Col" />{`.`}</li>
      <li parentName="ul">{`If the sum of col spans in a row are more than 12, then the overflowing col as a whole will start a new line arrangement.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      